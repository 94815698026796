












import Vue from "vue"

import UserReaction from "./UserReaction.vue"
export default Vue.extend({
  name: "AnimatedReactions",
  components: { UserReaction },
  props: ["users"],
  data() {
    return {
      reactionsList: {}
    }
  },
  computed: {
    reactions() {
      return this.users.reduce((acc, user) => {
        const currentTime = Date.now()
        const timestamp = user?.reaction?.timestamp

        if (timestamp && timestamp > currentTime - 4000) {
          acc[`${user.id}${timestamp}`] = user
        }

        return acc
      }, {})
    },
    animationDuration() {
      return this.reactionsList.length > 6 ? 3 : 4
    }
  },
  watch: {
    reactions(value) {
      Object.entries(value).forEach(([key, value]) => {
        this.$set(this.reactionsList, key, value)
      })
    }
  },
  methods: {
    removeReaction(id) {
      this.$delete(this.reactionsList, id)
    }
  }
})
