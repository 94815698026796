var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "live-chat-broadcast" },
    [
      _c(
        "div",
        { staticClass: "live-chat-broadcast__content" },
        [
          _c(
            "div",
            { staticClass: "live-chat-broadcast__live-indicator" },
            [
              _c("v-icon", { staticClass: "green--text" }, [
                _vm._v(" fiber_manual_record "),
              ]),
              _c("span", [_vm._v("LIVE")]),
            ],
            1
          ),
          _vm.chimePlaybackUrl
            ? _c("ChimeStream", {
                staticClass: "live-chat-broadcast__chime-stream",
                attrs: { playbackUrl: _vm.chimePlaybackUrl },
              })
            : _vm.$_LiveChatMixin_isUserBroadcasting
            ? _c("MeetingSmartGrid", {
                attrs: { users: _vm.users, "disable-mute": "" },
              })
            : _c("TwilioStream", {
                attrs: { id: _vm.clientID, type: _vm.StreamType.LOBBY },
              }),
        ],
        1
      ),
      _c("AnimatedReactions", {
        staticClass: "live-chat-broadcast__reactions",
        attrs: { users: _vm.reactions },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }