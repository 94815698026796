var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated-reactions" },
    _vm._l(_vm.reactionsList, function (user, id) {
      return _c("UserReaction", {
        key: id,
        attrs: { user: user, duration: _vm.animationDuration },
        on: {
          complete: function ($event) {
            return _vm.removeReaction(id)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }