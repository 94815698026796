

















import Vue from "vue"
import { TweenMax } from "gsap/TweenMax"

import UserImage from "@/components/GroupTeams/Common/User/UserImage.vue"
export default Vue.extend({
  name: "UserReaction",
  components: { UserImage },
  props: {
    user: {
      type: Object,
      default: undefined
    },
    duration: {
      type: Number,
      default: 4
    }
  },
  computed: {
    reactionName() {
      return this.user?.reaction?.name
    }
  },
  mounted() {
    const { x, y } = this.$el.getBoundingClientRect()

    const xPos = window.innerWidth - x - 60
    const yPos = window.innerHeight / 1.7 - y

    TweenMax.from(this.$el, this.duration, {
      scale: 1,
      opacity: 1,
      onComplete: () => {
        this.$emit("complete")
        this.tween = null
      },
      bezier: {
        curviness: 0.3,
        values: [
          { x: 0, y: 0 },
          { x: 10, y: yPos / 4 },
          { x: -10, y: yPos / 3 },
          { x: 20, y: yPos / 2 },
          { x: -20, y: yPos / 1.3 },
          { x: 0, y: yPos / 1.2 },
          { x: xPos, y: yPos }
        ]
      }
    })
  }
})
