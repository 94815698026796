<template>
  <div class="live-chat-broadcast">
    <div class="live-chat-broadcast__content">
      <div class="live-chat-broadcast__live-indicator">
        <v-icon class="green--text"> fiber_manual_record </v-icon>
        <span>LIVE</span>
      </div>
      <ChimeStream
        v-if="chimePlaybackUrl"
        :playbackUrl="chimePlaybackUrl"
        class="live-chat-broadcast__chime-stream"
      />
      <MeetingSmartGrid
        v-else-if="$_LiveChatMixin_isUserBroadcasting"
        :users="users"
        disable-mute
      />
      <TwilioStream v-else :id="clientID" :type="StreamType.LOBBY" />
    </div>
    <AnimatedReactions
      :users="reactions"
      class="live-chat-broadcast__reactions"
    />
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { db } from "@/firebase"
import GetterTypes from "@/store/getter-types"
import LiveChatMixin from "@/mixins/LiveChat"
import User from "@shared/User"
import AnimatedReactions from "@/components/GroupTeams/Common/Reactions/AnimatedReactions.vue"
import _ from "lodash"
import { StreamType } from "@/services/stream.service/enums"
import useStore from "@/use/useStore"
import { computed } from "@vue/composition-api"

export default Vue.extend({
  name: "LiveChatBroadcast",
  components: {
    ChimeStream: () => import("@/Chime/ChimeStream.vue"),
    MeetingSmartGrid: () =>
      import("@/components/GroupTeams/Common/GameModes/MeetingSmartGrid.vue"),
    TwilioStream: () => import("@/components/TwilioStream.vue"),
    AnimatedReactions
  },
  mixins: [LiveChatMixin],
  setup() {
    const { store } = useStore()
    const session = computed(() => store.getters["auth/client"])
    const chimePlaybackUrl = computed(() => session.value?.stream?.playbackUrl)
    return { StreamType, chimePlaybackUrl }
  },
  data() {
    return {
      data: null,
      reactions: [],
      db: db.auxiliary()
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "client", "clientID"]),
    ...mapGetters("livechat", ["roomID"]),
    users() {
      const users = User.normalize(this.data?.users)
      const online = this.$store.getters[GetterTypes.ONLINE_CLIENT_USERS]
      const reactions = this.reactions
      return online
        .filter(user => users.some(({ userID }) => userID === user.id))
        .map(user => ({
          ...user,
          reaction: reactions.find(obj => obj?.id === user.id)?.reaction
        }))
    },
    reactionsRef() {
      const { clientID: sessionID } = this
      const roomID = this.client?.stream?.roomID
      return (
        roomID &&
        this.db
          .ref(`client/${sessionID}/_calls/rooms/${roomID}/reaction`)
          .limitToLast(30)
      )
    },
    roomRef() {
      const { roomID, clientID: sessionID } = this
      return roomID && this.db.ref(`client/${sessionID}/calls/rooms/${roomID}`)
    }
  },
  watch: {
    reactionsRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.onReactionsUpdate)
        newValue?.on("value", this.onReactionsUpdate)
      },
      immediate: true
    },
    roomRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.onRoomUpdate)
        newValue?.on("value", this.onRoomUpdate)
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.roomRef?.off("value", this.onRoomUpdate)
    this.reactionsRef?.off("value", this.onReactionsUpdate)
  },
  methods: {
    onRoomUpdate(snapshot) {
      this.data = snapshot.val()
    },
    onReactionsUpdate: _.throttle(function (snapshot) {
      this.reactions = User.normalize(snapshot.val() || {})
        .map(({ user }) => user)
        .sort((a, b) => b.reaction?.timestamp - a.reaction?.timestamp)
    }, 500)
  }
})
</script>

<style lang="scss">
.live-chat-broadcast {
  flex: 1 1 auto;
  position: relative;
  background-color: #000;

  &__chime-stream {
    transform: none !important;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
  }

  &__live-indicator {
    position: absolute;
    z-index: 10;
    top: 8px;
    left: 10px;
    & > * {
      display: inline-block;
      vertical-align: middle;
    }
    & > span {
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
    }
  }

  .meeting-player-next {
    padding: 0 !important;
    &__body {
      border-radius: 0 !important;
      border: none !important;
      box-shadow: initial !important;
    }
  }

  &__reactions {
    position: absolute;
    top: 0;
    right: 40px;
  }

  .users-smart-grid__scrollable {
    overflow: hidden;
  }
}
</style>
